import React from 'react'
import Menu from './component/Basics/Menu';

function App() {
  return (
    <Menu/>
  );
}

export default App;
